'use client'

import { useState, useEffect } from 'react'
import { NextFont } from 'next/dist/compiled/@next/font'
import { UserProvider } from '@auth0/nextjs-auth0/client'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { LayoutProvider } from 'context/LayoutCtx'
import { NoticeProvider } from 'context/NoticeCtx'
import { type RegionsType } from 'types/regions'
import { type TenantsType } from '@consts/setupApp/tenants'
import { useAppSetupStore } from 'store/appSetup'
import getAppFont from '@theme/Fonts'
import getCustomTheme from '@theme'
import { mappTenant } from '@consts/tenants/front'

import { RatesProvider } from './rates-provider'
import { PermissionsProvider } from './permissions-provider'

const queryClient = new QueryClient()
const getCookie = (name: string) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)

  if (parts.length === 2) return parts.pop()?.split(';').shift()
}

export function Providers({ children }: { children: React.ReactNode }) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [theme, setTheme] = useState<Record<string, any>>()
  const [appFont, setAppFont] = useState<NextFont>()
  const handleSetup = useAppSetupStore((state) => state.handleSetup)

  useEffect(() => {
    const initialize = async () => {
      const tenant = getCookie('tenant') as TenantsType | undefined
      const region = getCookie('region')?.toLocaleLowerCase() as RegionsType | undefined

      if (!region || !tenant) return
      const tenantMapped = mappTenant[tenant] || tenant
      const theme = getCustomTheme({ tenant, region })

      setTheme(theme)
      setAppFont(getAppFont(tenantMapped))
      handleSetup({ tenant })
    }

    initialize()
  }, [])

  if (!appFont) return null

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <ColorModeScript initialColorMode={'light'} />
          <PermissionsProvider>
            <RatesProvider>
              <LayoutProvider>
                <NoticeProvider>
                  <main className={appFont.className}>{children}</main>
                </NoticeProvider>
              </LayoutProvider>
            </RatesProvider>
          </PermissionsProvider>
        </UserProvider>
      </QueryClientProvider>
    </ChakraProvider>
  )
}
