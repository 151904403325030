const Modal = {
  baseStyle: {
    fontFamily: 'body',
    dialogContainer: {
      py: { base: 4, xl: 6 }
    },
    dialog: {
      background: '#FAFAFA',
      color: '#515151',
      borderRadius: '20px',
      px: { base: 6, xl: 8 },
      py: { base: 4, xl: 6 }
    }
  },
  sizes: {
    xl: {
      dialog: {
        maxW: { base: 500, xl: 540 }
      }
    },
    xxl: {
      h: '60vh',
      w: '100vw'
    },
    '6xl': {
      dialog: {
        w: { base: 900, xl: 1100 }
      }
    },
    '7xl': {
      dialog: {
        w: { base: 1100 },
        px: 8,
        py: 6
      }
    },
    full: {
      dialogContainer: {
        padding: 0
      }
    }
  },
  variants: {
    alert: {
      dialog: {
        w: { base: 420, md: 566, xl: 582 },
        maxW: { base: '90vw', xl: '90vw' }
      }
    },
    bottom: {
      dialog: {
        position: 'absolute',
        h: '60vh',
        borderBottomRadius: 0,
        bottom: 0,
        m: 0
      }
    },
    pointed: {
      dialog: {
        borderRadius: 0
      }
    }
  }
}

export default Modal
