import localFont from 'next/font/local'
import { DM_Sans, Inter, Roboto } from 'next/font/google'
import TENANTS from '@consts/tenants'
import { type TenantsType } from '@consts/setupApp/tenants'
const {
  cbank,
  cbroker,
  hsp,
  prescientsecurity1,
  myv,
  demo_cl,
  gmf_cl,
  supv,
  casanuestra,
  demo_ar,
  maf
} = TENANTS

export const BlissPro = localFont({
  src: [
    {
      path: '../../public/fonts/bliss-pro/300.otf',
      weight: '300',
      style: 'normal'
    },
    {
      path: '../../public/fonts/bliss-pro/400.otf',
      weight: '400',
      style: 'italic'
    },
    {
      path: '../../public/fonts/bliss-pro/500.otf',
      weight: '500',
      style: 'normal'
    },
    {
      path: '../../public/fonts/bliss-pro/600.otf',
      weight: '600',
      style: 'italic'
    },
    {
      path: '../../public/fonts/bliss-pro/700.otf',
      weight: '700',
      style: 'italic'
    }
  ]
})

const TitilliumWeb = localFont({
  src: [
    {
      path: '../../public/fonts/titillium-web/300.ttf',
      weight: '300',
      style: 'normal'
    },
    {
      path: '../../public/fonts/titillium-web/400.ttf',
      weight: '400',
      style: 'italic'
    },
    {
      path: '../../public/fonts/titillium-web/500.ttf',
      weight: '500',
      style: 'normal'
    },
    {
      path: '../../public/fonts/titillium-web/600.ttf',
      weight: '600',
      style: 'italic'
    },
    {
      path: '../../public/fonts/titillium-web/700.ttf',
      weight: '700',
      style: 'italic'
    }
  ]
})

const DmSans = DM_Sans({ subsets: ['latin'] })
const InterFont = Inter({ subsets: ['latin'] })
const roboto = Roboto({ subsets: ['latin'], weight: ['100', '300', '400', '500', '700'] })

const FONTS_BY_TENANT: {
  [key: string]: {
    className: string
    style: {
      fontFamily: string
      fontWeight?: number
      fontStyle?: string
    }
  }
} = {
  [hsp]: TitilliumWeb,
  [cbank]: DmSans,
  [cbroker]: TitilliumWeb,
  [prescientsecurity1]: DmSans,
  [myv]: roboto,
  [maf]: roboto,
  [demo_cl]: DmSans,
  [gmf_cl]: TitilliumWeb,
  [supv]: InterFont,
  [casanuestra]: DmSans,
  [demo_ar]: DmSans
}

const DEFAULT_FONTS = TitilliumWeb

export default function getAppFont(tenant: TenantsType) {
  return FONTS_BY_TENANT[tenant] || DEFAULT_FONTS
}
